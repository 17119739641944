// extracted by mini-css-extract-plugin
export var bg = "CustomPageHeader-module--bg--uRxGG";
export var buttonAvailableOnMobile = "CustomPageHeader-module--buttonAvailableOnMobile--XL--2";
export var bxTextTruncateEnd = "CustomPageHeader-module--bx--text-truncate--end--fAyGV";
export var bxTextTruncateFront = "CustomPageHeader-module--bx--text-truncate--front--WGbr4";
export var darkMode = "CustomPageHeader-module--dark-mode--YanV5";
export var desc = "CustomPageHeader-module--desc--J8Xp-";
export var dotsElement = "CustomPageHeader-module--dots-element--ftR6i";
export var flex = "CustomPageHeader-module--flex--5oJ9L";
export var iconFilled = "CustomPageHeader-module--icon-filled--+DELM";
export var imageContainer = "CustomPageHeader-module--image-container--gSKGM";
export var introHeader = "CustomPageHeader-module--intro-header--uRl39";
export var label = "CustomPageHeader-module--label--QCqD3";
export var left = "CustomPageHeader-module--left--IAt6N";
export var maxWidthFull = "CustomPageHeader-module--max-width-full--EKu6V";
export var modal = "CustomPageHeader-module--modal--UDnmj";
export var modalDesc = "CustomPageHeader-module--modal-desc--vXN9t";
export var pageHeader = "CustomPageHeader-module--page-header--xV6HO";
export var platformContainer = "CustomPageHeader-module--platform-container--Xkzn9";
export var text = "CustomPageHeader-module--text--6z28o";
export var withTabs = "CustomPageHeader-module--with-tabs--TAxDP";