import React from "react";
import PropTypes from "prop-types";
import { Button, Modal } from "@legion-ui/core";
import { IconDeviceMobile, IconSquareRoundedArrowDownFilled } from "@tabler/icons-react";

import * as styles from "./CustomPageHeader.module.scss";
import DownloadSection from "./DownloadSection";

const HomepageHeaderSection = ({ description, image, label, navigateUrl, showModal, title, toggleModal }) => {
  return (
    <div className="bx--row" style={{ marginTop: "28px" }}>
      <div
        className={`bx--grid bx--col-lg-12 ${styles["flex"]}`}
        style={{ alignItems: "center" }}
      >
        <div className={styles.left}>
          <h3 className={styles.label}>{label}</h3>
          <h1 id="page-title" className={styles.text}>
            {title}
          </h1>
          <p className={styles.desc}>{description}</p>
          <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
            <Button
              variant="solid"
              size="md"
              onClick={navigateUrl}
              iconLeft={<IconSquareRoundedArrowDownFilled size={16} />}
            >
              Getting Started
            </Button>

            <Button
              variant="outline"
              onClick={toggleModal}
              size="md"
              iconLeft={<IconDeviceMobile size={16} />}
              className={styles.buttonAvailableOnMobile}
            >
              Mobile Playground
            </Button>
            {showModal && (
              <Modal
                show={showModal}
                title="Download Legion Mobile"
                onClose={toggleModal}
                width="94%"
                className={styles.modal}
              >
                <p className={styles.modalDesc}>
                  Download the mobile prototype to experience component
                  interactions and functionality firsthand
                </p>
                <div className={styles.platformContainer}>
                  <DownloadSection
                    platform="Android"
                    qrCodeUrl="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/qr/qr-android.png"
                    downloadUrl="https://play.google.com/store/apps/details?id=com.telkom.legion"
                  />
                  <DownloadSection
                    platform="iOS"
                    qrCodeUrl="https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/qr/qr-ios.png"
                    downloadUrl="https://apps.apple.com/id/app/legion/id6447614130"
                  />
                </div>
              </Modal>
            )}
          </div>
        </div>
        <img src={image} alt={`banner-${title}`} style={{ width: "460px" }} />
      </div>
    </div>
  );
};

HomepageHeaderSection.propTypes = {
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  navigateUrl: PropTypes.func,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  showModal: PropTypes.bool,
  toggleModal: PropTypes.func,
};

export default HomepageHeaderSection;
