const HeadingTableOfContents = (label) => {
  let heading = [];
  switch (label) {
    case "about-legion":
      heading = [
        {
          url: "what-is-legion",
          title: "What is Legion?",
        },
        {
          url: "boost-efficiency-with-legion",
          title: "Boost Efficiency with Legion",
        },
        {
          url: "we're-different-and-powerful",
          title: "We’re different and powerful",
        },
        {
          url: "save-your-time-and-build-more",
          title: "Save your time and build more",
        },
        {
          url: "our-advanced-features",
          title: "Our advanced features",
        },
        {
          url: "one-system-for-all",
          title: "One system for all",
        },
      ];
      break;
    case "design-getting-started-internal":
      heading = [
        {
          url: "Internal Design Library",
          title: "Internal Design Library",
        },
        {
          url: "Access Request",
          title: "Access Request",
        },
      ];
      break;
    case "design-getting-started-external":
      heading = [
        {
          url: "Community Design Library",
          title: "Community Design Library",
        },
        {
          url: "Usage Library",
          title: "Usage Library",
        },
      ];
      break;
    case "design-principles":
      heading = [
        {
          url: "Legion Principles",
          title: "Legion Principles",
        },
        {
          url: "Guiding Your Design Journey",
          title: "Guiding Your Design Journey",
        },
        {
          url: "Dynamic",
          title: "Dynamic",
        },
        {
          url: "Empowerment",
          title: "Empowerment",
        },
        {
          url: "Harmony",
          title: "Harmony",
        },
      ];
      break;
    case "design-project-setup":
      heading = [
        {
          url: "Project Structure",
          title: "Project Structure",
        },
        {
          url: "Project Naming",
          title: "Project Naming",
        },
        {
          url: "Folder Naming",
          title: "Folder Naming",
        },
        {
          url: "Design System Folder",
          title: "Design System Folder",
        },
        {
          url: "File Structure",
          title: "File Structure",
        },
        {
          url: "File Name and Thumbnail",
          title: "File Name & Thumbnail",
        },
      ];
      break;
    case "design-guideline-foundation-setup":
      heading = [
        {
          url: "Foundation Setup",
          title: "Foundation Setup",
        },
        {
          url: "Define colors from brand",
          title: "Define colors from brand",
        },
        {
          url: "Define right typography",
          title: "Define right typography",
        },
        {
          url: "Define Icon System and Illustration",
          title: "Define Icon System and Illustration",
        },
        {
          url: "Finish setup foundation",
          title: "Finish setup foundation",
        },
        {
          url: "Recommended Figma Plugins",
          title: "Recommended Figma Plugins",
        },
      ];
      break;
    case "design-guideline-ui-kit-setup":
      heading = [
        {
          url: "UI Kits Setup",
          title: "UI Kits Setup",
        },
        {
          url: "Connect your foundation",
          title: "Connect your foundation",
        },
        {
          url: "Connect to design token with asset code",
          title: "Connect to design token with asset code",
        },
        {
          url: "Recommended Figma Plugins",
          title: "Recommended Figma Plugins",
        },
      ];
      break;
    case "design-guideline-flow":
      heading = [
        {
          url: "Usage Annotation",
          title: "Usage Annotation",
        },
        {
          url: "Pluggin Recomendation",
          title: "Pluggin Recomendation",
        },
      ];
      break;
    case "design-guideline-screen":
      heading = [
        {
          url: "Usage Library",
          title: "Usage Library",
        },
        {
          url: "Template And Others Library",
          title: "Template & Others Library",
        },
        {
          url: "Pluggin Recomendation",
          title: "Pluggin Recomendation",
        },
      ];
      break;
    case "development-installation-android":
      heading = [
        {
          url: "Prequisites",
          title: "Prequisites",
        },
        {
          url: "install-legion-sdk-to-your-project",
          title: "Install Legion SDK to Your Project",
        },
        {
          url: "proxy-and-authentication",
          title: "Proxy and Authentication",
          subMenu: true,
        },
        {
          url: "install-or-add-dependency",
          title: "Install or Add Dependency",
          subMenu: true,
        },
        {
          url: "run-prototype-project",
          title: "Run Prototype Project",
          subMenu: true,
        },
      ];
      break;
    case "development-installation-website":
      heading = [
        {
          url: "Prequisites",
          title: "Prequisites",
        },
        {
          url: "Installation",
          title: "Installation",
        },
      ];
      break;
    case "development-installation-ios":
      heading = [
        {
          url: "Prequisites",
          title: "Prequisites",
        },
        {
          url: "Installation",
          title: "Installation",
        },
        {
          url: "Step",
          title: "Step",
        },
        {
          url: "Theme",
          title: "Theme",
        },
        {
          subMenu: true,
          url: "Setup",
          title: "Setup",
        },
        {
          url: "Instant Handoff",
          title: "Instant Handoff",
        },
        {
          subMenu: true,
          url: "Requirements",
          title: "Requirements",
        },
        {
          subMenu: true,
          url: "Getting Started",
          title: "Getting Started",
        },
        {
          subMenu: true,
          url: "Notes",
          title: "Notes",
        },
      ];
      break;
    case "development-configuration-android":
      heading = [
        {
          url: "Multi-Theme",
          title: "Multi-Theme",
        },
        {
          url: "Available Themes",
          title: "Available Themes",
        },
        {
          url: "Apply Prebuild Theme",
          title: "Apply Prebuild Theme",
        },
        {
          url: "Customize Themes",
          title: "Customize Themes",
        },
      ];
      break;
    case "development-configuration-website":
      heading = [
        {
          url: "Multi-Theme",
          title: "Multi-Theme",
        },
        {
          url: "ThemeProvider",
          title: "ThemeProvider",
        },
        {
          url: "Props",
          title: "Props",
        },
        {
          url: "Available Themes",
          title: "Available Themes",
        },
        {
          url: "Installing Fonts Theme",
          title: "Installing Fonts Theme",
        },
        {
          url: "Usage Themes",
          title: "Usage Themes",
        },
      ];
      break;
    case "development-configuration-ios":
      heading = [
        {
          url: "Import Theme",
          title: "Import Theme",
        },
        {
          url: "Step",
          title: "Step",
        },
      ];
      break;
    case "foundation-accessibility":
      heading = [
        {
          url: "What is Accessibility",
          title: "What is Accessibility?",
        },
        {
          url: "Structure and hierarchy",
          title: "Structure and hierarchy",
        },
        {
          url: "Typography",
          title: "Typography",
        },
        {
          url: "Colors",
          title: "Colors",
        },
        {
          url: "Images and video",
          title: "Images and video",
        },
        {
          url: "Resources",
          title: "Resources",
        },
      ];
      break;
    case "foundation-color-system-ios":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Usage Default LegionUI",
          title: "Usage Default LegionUI",
        },
        {
          subMenu: true,
          url: "Usage Theme",
          title: "Usage Theme",
        },
        {
          url: "Primary Colors",
          title: "Primary Colors",
        },
        {
          subMenu: true,
          url: "Primary Colors Default LegionUI",
          title: "Primary Color Default LegionUI",
        },
        {
          subMenu: true,
          url: "Primary Colors Theme",
          title: "Primary Colors Theme",
        },
        {
          url: "Secondary Colors",
          title: "Secondary Colors",
        },
        {
          subMenu: true,
          url: "Secondary Colors Default LegionUI",
          title: "Secondary Colors Default LegionUI",
        },
        {
          subMenu: true,
          url: "Secondary Colors Theme",
          title: "Secondary Colors Theme",
        },
        {
          url: "Tertiary Colors",
          title: "Tertiary Colors",
        },
        {
          subMenu: true,
          url: "Tertiary Colors Default LegionUI",
          title: "Tertiary Colors Default LegionUI",
        },
        {
          subMenu: true,
          url: "Tertiary Colors Theme",
          title: "Tertiary Colors Theme",
        },
        {
          url: "Semantic Colors",
          title: "Semantic Colors",
        },
        {
          url: "Success",
          title: "Success",
        },
        {
          subMenu: true,
          url: "Success Colors Default LegionUI",
          title: "Success Colors - Default LegionUI",
        },
        {
          subMenu: true,
          url: "Success Colors Theme",
          title: "Success Colors Theme",
        },
        {
          url: "Warning",
          title: "Warning",
        },
        {
          subMenu: true,
          url: "Warning Colors Default LegionUI",
          title: "Warning Colors Default LegionUI",
        },
        {
          subMenu: true,
          url: "Warning Colors Theme",
          title: "Warning Colors Theme",
        },
        {
          url: "Information",
          title: "Information",
        },
        {
          subMenu: true,
          url: "Information Colors Default LegionUI",
          title: "Information Colors Default LegionUI",
        },
        {
          subMenu: true,
          url: "Information Colors Theme",
          title: "Information Colors Theme",
        },
        {
          url: "Error",
          title: "Error",
        },
        {
          subMenu: true,
          url: "Error Colors Default LegionUI",
          title: "Error Colors Default LegionUI",
        },
        {
          subMenu: true,
          url: "Error Colors Theme",
          title: "Error Colors Theme",
        },
      ];
      break;
    case "foundation-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Preview",
          title: "Preview",
        },
        {
          subMenu: true,
          url: "Component",
          title: "Component",
        },
        {
          subMenu: true,
          url: "HTML Elements",
          title: "HTML Elements",
        },
      ];
      break;
    case "foundation-mobile":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Preview",
          title: "Preview",
        },
      ];
      break;
    case "foundation-color-system-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Color Primary",
          title: "Color Primary",
        },
        {
          url: "Color Secondary",
          title: "Color Secondary",
        },
        {
          url: "Color Tertiary",
          title: "Color Tertiary",
        },
        {
          url: "Color Accent",
          title: "Color Accent",
        },
      ];
      break;
    case "foundation-color-system-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Color Primary",
          title: "Color Primary",
        },
        {
          subMenu: true,
          url: "Color Secondary",
          title: "Color Secondary",
        },
        {
          subMenu: true,
          url: "Color Tertiary",
          title: "Color Tertiary",
        },
        {
          subMenu: true,
          url: "Color Accent",
          title: "Color Accent",
        },
        {
          url: "Example",
          title: "Example",
        },
      ];
      break;
    case "foundation-color-system-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Primary",
          title: "Primary",
        },
        {
          subMenu: true,
          url: "Secondary",
          title: "Secondary",
        },
        {
          subMenu: true,
          url: "Tertiary",
          title: "Tertiary",
        },
        {
          subMenu: true,
          url: "Accent",
          title: "Accent",
        },
        {
          subMenu: true,
          url: "Success",
          title: "Success",
        },
        {
          subMenu: true,
          url: "Warning",
          title: "Warning",
        },
        {
          subMenu: true,
          url: "Information",
          title: "Information",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          url: "Example",
          title: "Example",
        },
      ];
      break;
    case "foundation-color-system-guideline":
      heading = [
        {
          url: "Getting Colors Right",
          title: "Getting Colors Right",
        },
        {
          url: "Defining your color palette",
          title: "Defining your color palette",
        },
        {
          url: "Primary Colors",
          title: "Primary Colors",
        },
        {
          url: "Secondary Palette",
          title: "Secondary Palette",
        },
        {
          url: "Tertiary Palette",
          title: "Tertiary Palette",
        },
        {
          url: "Accent Colors",
          title: "Accent Colors",
        },
      ];
      break;
    case "foundation-typography-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Heading",
          title: "Heading",
        },
        {
          url: "Others",
          title: "Others",
        },
        {
          url: "Size",
          title: "Size",
        },
        {
          url: "Responsive",
          title: "Responsive",
        },
        {
          url: "Responsive Object",
          title: "Responsive Object",
          subMenu: true,
        },
        {
          url: "Responsive Relative",
          title: "Responsive Relative",
          subMenu: true,
        },
        {
          url: "Responsive Absolute",
          title: "Responsive Absolute",
          subMenu: true,
        },
      ];
      break;
    case "foundation-typography-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Heading",
          title: "Heading",
        },
        {
          url: "Body",
          title: "Body",
        },
        {
          url: "Caption",
          title: "Caption",
        },
      ];
      break;
    case "foundation-typography-guideline":
      heading = [
        {
          url: "Why Typography Matters",
          title: "Why Typography Matters?",
        },
        {
          url: "Defining your typography",
          title: "Defining your typography",
        },
        {
          url: "Typography rules",
          title: "Typography rules",
        },
      ];
      break;
    case "foundation-spacer-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Preview",
          title: "Preview",
        },
        {
          subMenu: true,
          url: "Component",
          title: "Component",
        },
        {
          subMenu: true,
          url: "HTML Elements",
          title: "HTML Elements",
        },
      ];
      break;
    case "foundation-spacer-mobile":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Preview",
          title: "Preview",
        },
      ];
      break;
    case "foundation-spacer-guideline":
      heading = [
        {
          url: "Why Spacers Matter",
          title: "Why Spacers Matter?",
        },
        {
          url: "Spacing units",
          title: "Spacing units",
        },
        {
          url: "Platform spesific",
          title: "Platform spesific",
        },
        {
          url: "Spacer Type",
          title: "Spacer Type",
        },
        {
          url: "Inline spacing",
          title: "Inline spacing",
        },
        {
          url: "Stack spacing",
          title: "Stack spacing",
        },
      ];
      break;
    case "foundation-radius-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Preview",
          title: "Preview",
        },
        {
          url: "Legion V2",
          title: "Legion V2",
        },
        {
          url: "HTML Elements",
          title: "HTML Elements",
        },
      ];
      break;
    case "foundation-radius-android":
      heading = [
        {
          url: "Preview",
          title: "Preview",
        },
      ];
      break;
    case "foundation-radius-guideline":
      heading = [
        {
          url: "Why Radius Matter",
          title: "Why Radius Matter?",
        },
        {
          url: "Spacing units",
          title: "Spacing units",
        },
        {
          url: "Platform spesific",
          title: "Platform spesific",
        },
        {
          url: "Spacer Type",
          title: "Spacer Type",
        },
        {
          url: "Inline spacing",
          title: "Inline spacing",
        },
        {
          url: "Stack spacing",
          title: "Stack spacing",
        },
      ];
      break;
    case "foundation-elevation-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Preview",
          title: "Preview",
        },
        {
          url: "Component",
          title: "Component",
        },
        {
          url: "HTML Elements",
          title: "HTML Elements",
        },
      ];
      break;
    case "foundation-elevation-mobile":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Preview",
          title: "Preview",
        },
      ];
      break;
    case "foundation-elevation-guideline":
      heading = [
        {
          url: "Why Elevation Matter",
          title: "Why Elevation Matter?",
        },
        {
          url: "Spacing units",
          title: "Spacing units",
        },
        {
          url: "Platform spesific",
          title: "Platform spesific",
        },
        {
          url: "Spacer Type",
          title: "Spacer Type",
        },
        {
          url: "Inline spacing",
          title: "Inline spacing",
        },
        {
          url: "Stack spacing",
          title: "Stack spacing",
        },
      ];
      break;
    case "components-anchor-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Basic",
          title: "Basic",
          subMenu: true,
        },
        {
          url: "Icons",
          title: "Icons",
          subMenu: true,
        },
        {
          url: "Disabled",
          title: "Disabled",
          subMenu: true,
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          url: "Size",
          title: "Size",
          subMenu: true,
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-anchor-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Large Size",
          title: "Large Size",
        },
        {
          subMenu: true,
          url: "Small Size",
          title: "Small Size",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-anchor-android-compose":
      heading = [
        {
          url: "Variants Sizes and States",
          title: "Variants, Sizes, and States",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Left Icon",
          title: "Left Icon",
        },
        {
          subMenu: true,
          url: "Right Icon",
          title: "Right Icon",
        },
        {
          url: "Sizes",
          title: "Sizes",
        },
        {
          subMenu: true,
          url: "Large Size",
          title: "Large Size",
        },
        {
          subMenu: true,
          url: "Medium Size",
          title: "Medium Size",
        },
        {
          subMenu: true,
          url: "Small Size",
          title: "Small Size",
        },
        {
          url: "States",
          title: "States",
        },
        {
          subMenu: true,
          url: "Active",
          title: "Active",
        },
        {
          subMenu: true,
          url: "Disabled",
          title: "Disabled",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-anchor-react-native":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Target",
          title: "Target",
        },
        {
          url: "Color",
          title: "Color",
        },
        {
          url: "Disabled",
          title: "Disabled",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-guideline":
      heading = [
        {
          url: "Anatomy",
          title: "Anatomy",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Accessibility",
          title: "Accessibility",
        },
        {
          url: "Content",
          title: "Content",
        },
      ];
      break;
    case "components-avatar-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Basic",
          title: "Basic",
          subMenu: true,
        },
        {
          url: "Additional badge",
          title: "Additional badge",
          subMenu: true,
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          url: "Size",
          title: "Size",
          subMenu: true,
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-badge-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Bordered",
          title: "Bordered",
          subMenu: true,
        },
        {
          url: "As Additionals",
          title: "As Additionals",
          subMenu: true,
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          url: "Size",
          title: "Size",
          subMenu: true,
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-button-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Color",
          title: "Color",
        },
        {
          url: "Size",
          title: "Size",
        },
        {
          url: "Icons",
          title: "Icons",
        },
        {
          url: "Disabled",
          title: "Disabled",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-button-android":
      heading = [
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Color Primary Theme",
          title: "Color Primary Theme",
        },
        {
          subMenu: true,
          url: "Color Secondary Theme",
          title: "Color Secondary Theme",
        },
        {
          subMenu: true,
          url: "Color Error Theme",
          title: "Color Error Theme",
        },
        {
          subMenu: true,
          url: "Fill Button Variant",
          title: "Fill Button Variant",
        },
        {
          subMenu: true,
          url: "Ghost Button Variant",
          title: "Ghost Button Variant",
        },
        {
          subMenu: true,
          url: "Outline Button Variant",
          title: "Outline Button Variant",
        },
        {
          subMenu: true,
          url: "Transparant Button Variant",
          title: "Transparant Button Variant",
        },
        {
          subMenu: true,
          url: "Large Button",
          title: "Large Button",
        },
        {
          subMenu: true,
          url: "Fit Button",
          title: "Fit Button",
        },
        {
          subMenu: true,
          url: "Slim Button",
          title: "Slim Button",
        },
        {
          subMenu: true,
          url: "Small Button",
          title: "Small Button",
        },
        {
          subMenu: true,
          url: "Loading State Fill Variant",
          title: "Loading State Fill Variant",
        },
        {
          subMenu: true,
          url: "Loading State Ghost Variant",
          title: "Loading State Ghost Variant",
        },
        {
          subMenu: true,
          url: "Loading State Outline Variant",
          title: "Loading State Outline Variant",
        },
        {
          subMenu: true,
          url: "Loading State Transparant Variant",
          title: "Loading State Transparant Variant",
        },
        {
          subMenu: true,
          url: "Disable State Fill Variant",
          title: "Disable State Fill Variant",
        },
        {
          subMenu: true,
          url: "Disabled State Ghost Variant",
          title: "Disabled State Ghost Variant",
        },
        {
          subMenu: true,
          url: "Disabled State Transparent Variant",
          title: "Disabled State Transparent Variant",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-button-react-native":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Solid",
          title: "Solid",
        },
        {
          subMenu: true,
          url: "Soft",
          title: "Soft",
        },
        {
          subMenu: true,
          url: "Outline",
          title: "Outline",
        },
        {
          subMenu: true,
          url: "Transparent",
          title: "Transparent",
        },
        {
          url: "Icon",
          title: "Icon",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-spinner-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic",
          title: "Basic",
        },
        {
          url: "Inside a Container",
          title: "Inside a Container",
        },
        {
          url: "Size",
          title: "Size",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-spinner-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-spinner-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Primary",
          title: "Primary",
        },
        {
          subMenu: true,
          url: "Secondary",
          title: "Secondary",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          subMenu: true,
          url: "White",
          title: "White",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-switch-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "Label",
          title: "Label",
        },
        {
          subMenu: true,
          url: "Disabled",
          title: "Disabled",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-switch-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-textfield-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "Label",
          title: "Label",
        },
        {
          subMenu: true,
          url: "Disabled",
          title: "Disabled",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          subMenu: true,
          url: "Caption",
          title: "Caption",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-textfield-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Basic text field",
          title: "Basic text field",
        },
        {
          subMenu: true,
          url: "Text Field with Label",
          title: "Text Field with Label",
        },
        {
          subMenu: true,
          url: "Text Field with Helper",
          title: "Text Field with Helper",
        },
        {
          subMenu: true,
          url: "Required Text Field",
          title: "Required Text Field",
        },
        {
          subMenu: true,
          url: "Optional Text Field",
          title: "Optional Text Field",
        },
        {
          subMenu: true,
          url: "State Normal",
          title: "State Normal",
        },
        {
          subMenu: true,
          url: "Error State",
          title: "Error State",
        },
        {
          subMenu: true,
          url: "Success  State",
          title: "Success  State",
        },
        {
          subMenu: true,
          url: "Disable State",
          title: "Disable State",
        },
        {
          subMenu: true,
          url: "Locked State",
          title: "Locked State",
        },
        {
          subMenu: true,
          url: "Prefix Text",
          title: "Prefix Text",
        },
        {
          subMenu: true,
          url: "Suffix Text",
          title: "Suffix Text",
        },
        {
          subMenu: true,
          url: "Left Icon",
          title: "Left Icon",
        },
        {
          subMenu: true,
          url: "Right Icon",
          title: "Right Icon",
        },
        {
          subMenu: true,
          url: "Prefix with Left Icon",
          title: "Prefix with Left Icon",
        },
        {
          subMenu: true,
          url: "Prefix with Right Icon",
          title: "Prefix with Right Icon",
        },
        {
          subMenu: true,
          url: "Suffix with Left Icon",
          title: "Suffix with Left Icon",
        },
        {
          subMenu: true,
          url: "Suffix with Right Icon",
          title: "Suffix with Right Icon",
        },
        {
          subMenu: true,
          url: "Password Field",
          title: "Password Field",
        },
        {
          subMenu: true,
          url: "Date Field",
          title: "Date Field",
        },
        {
          subMenu: true,
          url: "Time Field",
          title: "Time Field",
        },
        {
          subMenu: true,
          url: "Dropdown  Field",
          title: "Dropdown  Field",
        },
        {
          subMenu: true,
          url: "Unit Field",
          title: "Unit Field",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-textfield-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "With Label",
          title: "With Label",
        },
        {
          subMenu: true,
          url: "State",
          title: "State",
        },
        {
          subMenu: true,
          url: "Prefix Text",
          title: "Prefix Text",
        },
        {
          subMenu: true,
          url: "Suffix Text",
          title: "Suffix Text",
        },
        {
          subMenu: true,
          url: "Left Icon",
          title: "Left Icon",
        },
        {
          subMenu: true,
          url: "Right Icon",
          title: "Right Icon",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-textfield-react-native":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Textfield Inline",
          title: "Textfield Inline",
        },
        {
          url: "Textfield Outline",
          title: "Textfield Outline",
        },
        {
          url: "With Hint",
          title: "With Hint",
        },
        {
          url: "With Label",
          title: "With Label",
        },
        {
          url: "onChange",
          title: "onChange",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-textfield-guideline":
      heading = [
        {
          url: "Anatomy",
          title: "Anatomy",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Accessibility",
          title: "Accessibility",
        },
        {
          url: "Content",
          title: "Content",
        },
      ];
      break;
    case "components-tooltip-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Title",
          title: "Title",
        },
        {
          subMenu: true,
          url: "Placement",
          title: "Placement",
        },
        {
          subMenu: true,
          url: "Not Arrow",
          title: "Not Arrow",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "pattern-loginandregister-website":
      heading = [
        {
          url: "Website Pattern Introduction",
          title: "Website Pattern Introduction",
        },
        {
          url: "Case : Mobile Number as Primary Key",
          title: "Mobile Number as Primary Key",
        },
        {
          url: "Case : Username as Primary Key",
          title: "Username as Primary Key",
        },
        {
          url: "Case : Email Address as Primary Key",
          title: "Email Address as Primary Key",
        },
        {
          url: "Case : Telkom (NIK) as Primary Key",
          title: "Telkom (NIK) as Primary Key",
        },
        {
          url: "Case : Third Party (SSO) as Primary Key",
          title: "Third Party (SSO) as Primary Key",
        },
      ];
      break;
    case "pattern-loginandregister-mobile":
      heading = [
        {
          url: "Mobile Pattern Introduction",
          title: "Mobile Pattern Introduction",
        },
        {
          url: "Case : Mobile Number as Primary Key",
          title: "Mobile Number as Primary Key",
        },
        {
          url: "Case : Username as Primary Key",
          title: "Username as Primary Key",
        },
        {
          url: "Case : Email Address as Primary Key",
          title: "Email Address as Primary Key",
        },
        {
          url: "Case : Telkom (NIK) as Primary Key",
          title: "Telkom (NIK) as Primary Key",
        },
        {
          url: "Case : Third Party (SSO) as Primary Key",
          title: "Third Party (SSO) as Primary Key",
        },
      ];
      break;
    case "pattern-onboarding-website":
      heading = [
        {
          url: "Yaml data",
          title: "Yaml data",
        },
        {
          url: "Adding a divider",
          title: "Adding a divider",
        },
        {
          url: "Customizing",
          title: "Customizing",
        },
      ];
      break;
    case "pattern-onboarding-mobile":
      heading = [
        {
          url: "Case : Content is Mandatory",
          title: "Content is Mandatory?",
        },
        {
          url: "Case : Content is Optional",
          title: "Content is Optional",
        },
        {
          url: "Case : Without Content",
          title: "Without Content",
        },
      ];
      break;
    case "pattern-user-management-guideline":
      heading = [
        {
          url: "Introduction",
          title: "Introduction",
        },
        {
          url: "User Management",
          title: "User Management",
        },
        {
          url: "Role Management",
          title: "Role Management",
        },
        {
          url: "Permission Management",
          title: "Permission Management",
        },
      ];
      break;
    case "pattern-user-management-use-case":
      heading = [
        {
          url: "case:-access-control-list-(acl)",
          title: "Case: Access Control List (ACL)",
        },
        {
          url: "case:-role-based-access-control-(rbac)",
          title: "Case: Role Based Access Control (RBAC)",
        },
      ];
      break;
    case "content-writing-experience":
      heading = [
        {
          url: "Button Call to Action",
          title: "Button / Call to action",
        },
        {
          url: "Empty States",
          title: "Empty States",
        },
        {
          url: "Error Messages",
          title: "Error Messages",
        },
        {
          url: "Success Messages",
          title: "Success Messages",
        },
        {
          url: "Placeholders",
          title: "Placeholders",
        },
        {
          url: "Labels",
          title: "Labels",
        },
        {
          url: "Notifications",
          title: "Notifications",
        },
      ];
      break;
    case "content-guideline":
      heading = [
        {
          url: "Guideline Usage",
          title: "Guideline Usage",
        },
        {
          url: "Writing Principles",
          title: "Writing Principles",
        },
      ];
      break;
    case "release-notes":
      heading = [
        {
          url: "Latest Release",
          title: "Latest Release",
        },
        {
          url: "Website Updates",
          title: "Website Updates",
        },
        {
          url: "Component Design",
          title: "Component Design",
        },
        {
          url: "Pattern Updates",
          title: "Pattern Updates",
        },
        {
          url: "Previous Release",
          title: "Previous Release",
        },
      ];
      break;
    case "components-slider-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Example",
          title: "Example",
        },
        {
          subMenu: true,
          url: "Slider with Value",
          title: "Slider with Value",
        },
        {
          subMenu: true,
          url: "Disabled",
          title: "Disabled",
        },
        {
          subMenu: true,
          url: "Range Slider",
          title: "Range Slider",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-snackbar-android":
      heading = [
        {
          url: "Variants-And-Themes",
          title: "Variants & Themes",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Normal Snackbar",
          title: "Normal Snackbar",
        },
        {
          subMenu: true,
          url: "Success Snackbar",
          title: "Success Snackbar",
        },
        {
          subMenu: true,
          url: "Error Snackbar",
          title: "Error Snackbar",
        },
        {
          subMenu: true,
          url: "Warning Snackbar",
          title: "Warning Snackbar",
        },
        {
          url: "Variants Usage",
          title: "Variants Usage",
        },
        {
          subMenu: true,
          url: "Title",
          title: "Title",
        },
        {
          subMenu: true,
          url: "Description",
          title: "Description",
        },
        {
          subMenu: true,
          url: "Action Text",
          title: "Action Text",
        },
        {
          subMenu: true,
          url: "Icon",
          title: "Icon",
        },
        {
          subMenu: true,
          url: "Avatar",
          title: "Avatar",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
      ];
      break;
    case "components-snackbar-android-compose":
      heading = [
        {
          url: "Variants-And-Themes",
          title: "Variants & Themes",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Normal Snackbar",
          title: "Normal Snackbar",
        },
        {
          subMenu: true,
          url: "Success Snackbar",
          title: "Success Snackbar",
        },
        {
          subMenu: true,
          url: "Error Snackbar",
          title: "Error Snackbar",
        },
        {
          subMenu: true,
          url: "Warning Snackbar",
          title: "Warning Snackbar",
        },
        {
          url: "Custom Snackbar",
          title: "Custom Snackbar",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-card-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Hoverable",
          title: "Hoverable",
        },
        {
          url: "Example",
          title: "Example",
        },
      ];
      break;
    case "components-textarea-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "Label",
          title: "Label",
        },
        {
          subMenu: true,
          url: "Disabled",
          title: "Disabled",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          subMenu: true,
          url: "Caption",
          title: "Caption",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-textarea-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic",
          title: "Basic",
        },
        {
          url: "Required Text Area",
          title: "Required Text Area",
        },
        {
          url: "Optional Text Area",
          title: "Optional Text Area",
        },
        {
          url: "Disabled Text Area",
          title: "Disabled Text Area",
        },
        {
          url: "Error Indicator Text Area",
          title: "Error Indicator Text Area",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-textarea-android-compose":
      heading = [
        {
          url: "Types and States",
          title: "Types and States",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Inline",
          title: "Inline",
        },
        {
          subMenu: true,
          url: "Outline",
          title: "Outline",
        },
        {
          url: "States",
          title: "States",
        },
        {
          subMenu: true,
          url: "Normal",
          title: "Normal",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          subMenu: true,
          url: "Success",
          title: "Success",
        },
        {
          subMenu: true,
          url: "Disabled",
          title: "Disabled",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-textarea-react-native":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Textarea Inline",
          title: "Textarea Inline",
        },
        {
          url: "Textarea Outline",
          title: "Textarea Outline",
        },
        {
          url: "With Hint",
          title: "With Hint",
        },
        {
          url: "With Label",
          title: "With Label",
        },
        {
          url: "onChange",
          title: "onChange",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-accordion-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "Accordion with icon",
          title: "Accordion with icon",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Accordion without divider",
          title: "Accordion without divider",
        },
        {
          subMenu: true,
          url: "Accordion with divider",
          title: "Accordion with divider",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
        {
          url: "Example",
          title: "Example",
        },
      ];
      break;
    case "components-accordion-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Basic Accordion",
          title: "Basic Accordion",
        },
        {
          subMenu: true,
          url: "Accordion with Icon",
          title: "Accordion with Icon",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Accordion with Divider",
          title: "Accordion with Divider",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
        {
          url: "Example",
          title: "Example",
        },
      ];
      break;
    case "components-checkbox-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-checkbox-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Base Components",
          title: "Base Components",
        },
        {
          subMenu: true,
          url: "Checkbox Group",
          title: "Checkbox Group",
        },
        {
          subMenu: true,
          url: "Checkbox Container",
          title: "Checkbox Container",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Primary Color Checkbox",
          title: "Primary Color Checkbox",
        },
        {
          subMenu: true,
          url: "Secondary Color Checkbox",
          title: "Secondary Color Checkbox",
        },
        {
          subMenu: true,
          url: "Primary Color Checkbox Group",
          title: "Primary Color Checkbox Group",
        },
        {
          subMenu: true,
          url: "Secondary Color Checkbox Group",
          title: "Secondary Color Checkbox Group",
        },
        {
          subMenu: true,
          url: "Primary Color Checkbox Container",
          title: "Primary Color Checkbox Container",
        },
        {
          subMenu: true,
          url: "Secondary Color Checkbox Container",
          title: "Secondary Color Checkbox Container",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
        {
          subMenu: true,
          url: "Base Component Attributes",
          title: "Base Component Attributes",
        },
        {
          subMenu: true,
          url: "Checkbox Group Attributes",
          title: "Checkbox Group Attributes",
        },
        {
          subMenu: true,
          url: "Checkbox Container Attributes",
          title: "Checkbox Container Attributes",
        },
      ];
      break;
    case "components-checkbox-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Single",
          title: "Single",
        },
        {
          subMenu: true,
          url: "Group",
          title: "Group",
        },
      ];
      break;
    case "components-divider-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic",
          title: "Basic",
        },
        {
          url: "Split it",
          title: "Split it",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-divider-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic",
          title: "Basic",
        },
        {
          url: "Integrate with RecyclerView",
          title: "Integrate with RecyclerView",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-divider-ios":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "contribute-and-request":
      heading = [
        {
          url: "A Guide on How to Contribute",
          title: "A Guide on How to Contribute",
        },
      ];
      break;
    case "release-notes-mobile":
      heading = [
        {
          url: "Latest Release",
          title: "Latest Release",
        },
        {
          url: "Development Library Updates",
          title: "Development Library Updates",
        },
        {
          url: "Design Library Updates",
          title: "Design Library Updates",
        },
        {
          url: "Previous Release",
          title: "Previous Release",
        },
      ];
      break;
    case "components-table-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic Table",
          title: "Basic Table",
        },
        {
          url: "Data Table",
          title: "Data Table",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-select-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Basic Select",
          title: "Basic Select",
        },
        {
          subMenu: true,
          url: "Disabled",
          title: "Disabled",
        },
        {
          subMenu: true,
          url: "Icon",
          title: "Icon",
        },
        {
          subMenu: true,
          url: "Label and Caption",
          title: "Label and Caption",
        },
        {
          subMenu: true,
          url: "Multi Select",
          title: "Multi Select",
        },
        {
          subMenu: true,
          url: "Show Select Option",
          title: "Show Select Option",
        },
        {
          subMenu: true,
          url: "Open Menu onSelect",
          title: "Open Menu onSelect",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-navigation-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Basic Navigation Bar",
          title: "Basic Navigation Bar",
        },
        {
          subMenu: true,
          url: "Navigation with Menu",
          title: "Navigation with Menu",
        },
        {
          subMenu: true,
          url: "Navigation with Search",
          title: "Navigation with Search",
        },
        {
          subMenu: true,
          url: "Navigation with Avatar",
          title: "Navigation with Avatar",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-accordion-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Accordion with Left Icon",
          title: "with Left Icon",
          subMenu: true,
        },
        {
          url: "Accordion without Icon",
          title: "without Icon",
          subMenu: true,
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-sidebar-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "With Sub Menu",
          title: "With Sub Menu",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-ios":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Type",
          title: "Type",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          url: "Properties",
          title: "Properties",
        },
      ];
      break;
    case "components-basic-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
      ];
      break;
    case "components-basic-with-props-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-ios-snackbar":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Sizes",
          title: "Sizes",
        },
        {
          subMenu: true,
          url: "Medium",
          title: "Medium",
        },
        {
          subMenu: true,
          url: "Small",
          title: "Small",
        },
        {
          url: "Styles",
          title: "Styles",
        },
        {
          subMenu: true,
          url: "Solid Default",
          title: "Solid Default",
        },
        {
          subMenu: true,
          url: "Soft Default",
          title: "Soft Default",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Tertiary",
          title: "Tertiary",
        },
        {
          subMenu: true,
          url: "Warning",
          title: "Warning",
        },
        {
          subMenu: true,
          url: "Information",
          title: "Information",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          subMenu: true,
          url: "Success",
          title: "Success",
        },
        {
          subMenu: true,
          url: "Tertiary",
          title: "Tertiary",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
        {
          url: "Important Notes",
          title: "Important Notes",
        },
      ];
      break;
    case "components-ios-checkbox":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          subMenu: true,
          url: "Disable",
          title: "Disable",
        },
        {
          subMenu: true,
          url: "Label",
          title: "Label",
        },
        {
          subMenu: true,
          url: "Mixed",
          title: "Mixed",
        },
        {
          subMenu: true,
          url: "Background",
          title: "Background",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "List of viewModifier",
          title: "List of viewModifier",
        },
      ];
      break;
    case "components-ios-avatar":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Avatar Image",
          title: "Avatar Image",
        },
        {
          subMenu: true,
          url: "Avatar Icon",
          title: "Avatar Icon",
        },
        {
          subMenu: true,
          url: "Avatar Initial",
          title: "Avatar Initial",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          subMenu: true,
          url: "Show Progress",
          title: "Show Progress",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          subMenu: true,
          url: "LGNAvatarImage",
          title: "LGNAvatarImage",
        },
        {
          subMenu: true,
          url: "LGNAvatarIcon",
          title: "LGNAvatarIcon",
        },
        {
          subMenu: true,
          url: "LGNAvatarInitials",
          title: "LGNAvatarInitials",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-ios-button":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Usage With Theme",
          title: "UsageWith Theme",
        },
        {
          subMenu: true,
          url: "Usage Without Theme",
          title: "Usage Without Theme",
        },
        {
          url: "Solid",
          title: "Solid",
        },
        {
          url: "Outline",
          title: "Outline",
        },
        {
          url: "Soft",
          title: "Soft",
        },
        {
          url: "Transparent",
          title: "Transparent",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "List of viewModifier",
          title: "List of viewModifier",
        },
      ];
      break;
    case "components-ios-radiobutton":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          subMenu: true,
          url: "Disable",
          title: "Disable",
        },
        {
          subMenu: true,
          url: "Label",
          title: "Label",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-ios-switch":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          subMenu: true,
          url: "Disable",
          title: "Disable",
        },
        {
          subMenu: true,
          url: "Label",
          title: "Label",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-ios-textarea":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Outline",
          title: "Outline",
        },
        {
          subMenu: true,
          url: "Inline",
          title: "Inline",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "ThemeAGR",
          title: "ThemeAGR",
        },
        {
          subMenu: true,
          url: "ThemeMyTEnS",
          title: "ThemeMyTEnS",
        },
        {
          subMenu: true,
          url: "ThemeIHS",
          title: "ThemeIHS",
        },
        {
          subMenu: true,
          url: "ThemeEazy",
          title: "ThemeEazy",
        },
        {
          url: "Properties",
          title: "Properties",
        },
      ];
      break;
    case "components-ios-textfield":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Outline",
          title: "Outline",
        },
        {
          subMenu: true,
          url: "Outline State",
          title: "Outline State",
        },
        {
          subMenu: true,
          url: "Outline Left Icon",
          title: "Outline Left Icon",
        },
        {
          subMenu: true,
          url: "Outline Secure Text",
          title: "Outline Secure Text",
        },
        {
          subMenu: true,
          url: "Outline Caption",
          title: "Outline Caption",
        },
        {
          subMenu: true,
          url: "Outline Prefix",
          title: "Outline Prefix",
        },
        {
          subMenu: true,
          url: "Outline Suffix",
          title: "Outline Suffix",
        },
        {
          subMenu: true,
          url: "Outline Hint Text",
          title: "Outline Hint Text",
        },
        {
          url: "Inline",
          title: "Inline",
        },
        {
          subMenu: true,
          url: "Inline State",
          title: "Inline State",
        },
        {
          subMenu: true,
          url: "Inline Left Icon",
          title: "Inline Left Icon",
        },
        {
          subMenu: true,
          url: "Inline Secure Text",
          title: "Inline Secure Text",
        },
        {
          subMenu: true,
          url: "Inline Caption",
          title: "Inline Caption",
        },
        {
          subMenu: true,
          url: "Inline Prefix",
          title: "Inline Prefix",
        },
        {
          subMenu: true,
          url: "Inline Suffix",
          title: "Inline Suffix",
        },
        {
          subMenu: true,
          url: "Inline Hint Text",
          title: "Inline Hint Text",
        },
        {
          url: "Properties",
          title: "Properties",
        },
      ];
      break;
    case "components-ios-accordion":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Line",
          title: "Line",
        },
        {
          subMenu: true,
          url: "Icon",
          title: "Icon",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-ios-tooltip":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Position",
          title: "Position",
        },
        {
          subMenu: true,
          url: "Bottom Center",
          title: "Bottom Center",
        },
        {
          subMenu: true,
          url: "Bottom Left",
          title: "Bottom Left",
        },
        {
          subMenu: true,
          url: "Bottom Right",
          title: "Bottom Right",
        },
        {
          subMenu: true,
          url: "Top Center",
          title: "Top Center",
        },
        {
          subMenu: true,
          url: "Left",
          title: "Left",
        },
        {
          subMenu: true,
          url: "Right",
          title: "Right",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          subMenu: true,
          url: "LGNTooltipBase properties",
          title: "LGNTooltipBase properties",
        },
        {
          subMenu: true,
          url: "lgnTooltip view modifier function properties",
          title: "lgnTooltip view modifier function properties",
        },
        {
          subMenu: true,
          url: "LGNTooltipIcon properties",
          title: "LGNTooltipIcon properties",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-ios-bottomsheet":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Header",
          title: "Header",
        },
        {
          subMenu: true,
          url: "Button Position",
          title: "Button Position",
        },
        {
          url: "Properties",
          title: "Properties",
        },
      ];
      break;
    case "components-bottomsheet-react-native":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Anatomy",
          title: "Anatomy",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-ios-chips":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          subMenu: true,
          url: "Active",
          title: "Active",
        },
        {
          subMenu: true,
          url: "Disable",
          title: "Disable",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-chips-react-native":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Solid",
          title: "Solid",
        },
        {
          subMenu: true,
          url: "Soft",
          title: "Soft",
        },
        {
          subMenu: true,
          url: "Outline",
          title: "Outline",
        },
        {
          url: "isClosable",
          title: "isClosable",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-ios-anchor":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          subMenu: true,
          url: "With Title",
          title: "With Title",
        },
        {
          subMenu: true,
          url: "With centerImage (Without Title)",
          title: "With centerImage (Without Title)",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-image-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-image-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "Image Radius",
          title: "Image Radius",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-imageUpload-android":
      heading = [
        {
          url: "Theme",
          title: "Theme",
        },
        {
          subMenu: true,
          url: "Image Upload Info",
          title: "Image Upload Info",
        },
        {
          subMenu: true,
          url: "Image Upload Success",
          title: "Image Upload Success",
        },
        {
          subMenu: true,
          url: "Image Upload Error",
          title: "Image Upload Error",
        },
        {
          subMenu: true,
          url: "Image Upload Helper",
          title: "Image Upload Helper",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Image Upload with Hint and Helper",
          title: "Image Upload with Hint and Helper",
        },
        {
          subMenu: true,
          url: "Image Upload only with hint",
          title: "Image Upload only with hint",
        },
        {
          subMenu: true,
          url: "Image Upload only with Helper",
          title: "Image Upload only with Helper",
        },
        {
          subMenu: true,
          url: "Image Upload without Hint and Helper",
          title: "Image Upload without Hint and Helper",
        },
        {
          url: "State",
          title: "State",
        },
        {
          subMenu: true,
          url: "Enable Image Upload",
          title: "Enable Image Upload",
        },
        {
          subMenu: true,
          url: "Disable Image Upload",
          title: "Disable Image Upload",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-navigation-android":
      heading = [
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "1. Navbar Title",
          title: "1. Navbar Title",
        },
        {
          subMenu: true,
          url: "2. Navbar Title and Description",
          title: "2. Navbar Title and Description",
        },
        {
          subMenu: true,
          url: "3. Navbar Title Large",
          title: "3. Navbar Title Large",
        },
        {
          subMenu: true,
          url: "4. Navbar Title and Description Large",
          title: "4. Navbar Title and Description Large",
        },
        {
          subMenu: true,
          url: "5. Navbar Search Bar",
          title: "5. Navbar Search Bar",
        },
        {
          subMenu: true,
          url: "6. Navbar Search Bar with Title",
          title: "6. Navbar Search Bar with Title",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
      ];
      break;
    case "components-avatar-android":
      heading = [
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Avatar with Badge Dot",
          title: "Avatar with Badge Dot",
        },
        {
          subMenu: true,
          url: "Avatar with Badge Icon",
          title: "Avatar with Badge Icon",
        },
        {
          subMenu: true,
          url: "Avatar with Badge Number",
          title: "Avatar with Badge Number",
        },
        {
          subMenu: true,
          url: "Avatar Image Url",
          title: "Avatar Image Url",
        },
        {
          subMenu: true,
          url: "Avatar Initial Text",
          title: "Avatar Initial Text",
        },
        {
          subMenu: true,
          url: "Avatar Image Icon",
          title: "Avatar Image Icon",
        },
        {
          subMenu: true,
          url: "Avatar Icon",
          title: "Avatar Icon",
        },
        {
          subMenu: true,
          url: "Avatar Large",
          title: "Avatar Large",
        },
        {
          subMenu: true,
          url: "Avatar Medium",
          title: "Avatar Medium",
        },
        {
          subMenu: true,
          url: "Avatar Small",
          title: "Avatar Small",
        },
      ];
      break;
    case "components-avatar-android-compose":
      heading = [
        {
          url: "Variants and Sizes",
          title: "Variants and Sizes",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "With Badge Dot",
          title: "With Badge Dot",
        },
        {
          subMenu: true,
          url: "With Badge Icon",
          title: "With Badge Icon",
        },
        {
          subMenu: true,
          url: "With Badge Number",
          title: "With Badge Number",
        },
        {
          subMenu: true,
          url: "Attaching Image",
          title: "Attaching Image",
        },
        {
          subMenu: true,
          url: "With Initial Text",
          title: "With Initial Text",
        },
        {
          subMenu: true,
          url: "With Icon",
          title: "With Icon",
        },
        {
          url: "Sizes",
          title: "Sizes",
        },
        {
          subMenu: true,
          url: "Large Size",
          title: "Large Size",
        },
        {
          subMenu: true,
          url: "Medium Size",
          title: "Medium Size",
        },
        {
          subMenu: true,
          url: "Small Size",
          title: "Small Size",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-avatar-react-native":
      heading = [
        {
          usage: "Usage",
          title: "Usage",
        },
        {
          usage: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-dropdown-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Basic Dropdown",
          title: "Basic",
          subMenu: true,
        },
        {
          url: "Dropdown with Icon",
          title: "with Icon",
          subMenu: true,
        },
        {
          url: "Dropdown with Description",
          title: "with Description",
          subMenu: true,
        },
        {
          url: "Dropdown with Checkbox",
          title: "with Checkbox",
          subMenu: true,
        },
        {
          url: "Dropdown with Multiple Categories",
          title: "with Multiple Categories",
          subMenu: true,
        },
        {
          url: "Dropdown with Toggle",
          title: "with Toggle",
          subMenu: true,
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-dropdown-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "content-writing-principles":
      heading = [
        {
          url: "Background",
          title: "Background",
        },
        {
          url: "Our Communication",
          title: "Our Communication",
        },
        {
          url: "Respect",
          title: "Respect",
        },
        {
          url: "Guide",
          title: "Guide",
        },
        {
          url: "Encourage",
          title: "Encourage",
        },
        {
          url: "Efficient",
          title: "Efficient",
        },
        {
          url: "Communication Rules",
          title: "Communication Rules",
        },
        {
          url: "Concise",
          title: "Concise",
        },
        {
          url: "Empathize",
          title: "Empathize",
        },
        {
          url: "Consistent",
          title: "Consistent",
        },
      ];
      break;
    case "content-writing-patterns":
      heading = [
        {
          url: "Thing We Should Know",
          title: "Thing We Should Know",
        },
        {
          url: "Titles",
          title: "Titles",
        },
        {
          url: "Descriptions",
          title: "Descriptions",
        },
        {
          url: "Section Title",
          title: "Section Title",
        },
      ];
      break;
    case "content-writing-mechanics":
      heading = [
        {
          url: "Background",
          title: "Background",
        },
        {
          url: "Currency",
          title: "Currency",
        },
        {
          url: "date and time",
          title: "Date & Time",
        },
        {
          url: "Numbers",
          title: "Numbers",
        },
        {
          url: "Phone Number",
          title: "Phone Number",
        },
        {
          url: "Measurements",
          title: "Measurements",
        },
        {
          url: "temperature coordinate and angle",
          title: "Temperature, Coordinate, and Angle",
        },
        {
          url: "File Extensions",
          title: "File Extensions",
        },
        {
          url: "Institution",
          title: "Institution",
        },
        {
          url: "Cities and Countries",
          title: "Cities & Countries",
        },
        {
          url: "URLs",
          title: "URLs",
        },
      ];
      break;
    case "content-writing-grammar":
      heading = [
        {
          url: "Background",
          title: "Background",
        },
        {
          url: "Capitalization",
          title: "Capitalization",
        },
        {
          url: "Apostrophe",
          title: "Apostrophe",
        },
        {
          url: "Quotation Marks",
          title: "Quotation Marks",
        },
        {
          url: "Colons",
          title: "Colons",
        },
        {
          url: "Commas",
          title: "Commas",
        },
        {
          url: "range spans and dashes",
          title: "Range Spans & Dashes",
        },
        {
          url: "Ellipses",
          title: "Ellipses",
        },
        {
          url: "Periods",
          title: "Periods",
        },
        {
          url: "Question Marks",
          title: "Question Marks",
        },
        {
          url: "Exclamation Points",
          title: "Exclamation Points",
        },
        {
          url: "Pronouns and Referring to Product",
          title: "Pronouns and Referring to Product",
        },
      ];
      break;
    case "components-chips-android":
      heading = [
        {
          url: "Variants",
          title: "Variants",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Primary",
          title: "Primary",
        },
        {
          subMenu: true,
          url: "Secondary",
          title: "Secondary",
        },
        {
          subMenu: true,
          url: "Large",
          title: "Large",
        },
        {
          subMenu: true,
          url: "Small",
          title: "Small",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-chips-android-compose":
      heading = [
        {
          url: "Variants",
          title: "Variants",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Primary",
          title: "Primary",
        },
        {
          subMenu: true,
          url: "Secondary",
          title: "Secondary",
        },
        {
          subMenu: true,
          url: "Large",
          title: "Large",
        },
        {
          subMenu: true,
          url: "Small",
          title: "Small",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-modal-android":
      heading = [
        {
          url: "Variants",
          title: "Variants",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Basic",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "Modal With Title",
          title: "Modal With Title",
        },
        {
          subMenu: true,
          url: "Modal With Description",
          title: "Modal With Description",
        },
        {
          subMenu: true,
          url: "Modal With Button",
          title: "Modal With Button",
        },
        {
          subMenu: true,
          url: "Modal With Show Close",
          title: "Modal With Show Close",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-modal-android-compose":
      heading = [
        {
          url: "Basic Example",
          title: "Basic Example",
        },
        {
          subMenu: true,
          url: "Basic Modal",
          title: "Basic Modal",
        },
        {
          subMenu: true,
          url: "Basic Modal Attributes",
          title: "Basic Modal Attributes",
        },
        {
          url: "Decoration Example",
          title: "Decoration Example",
        },
        {
          subMenu: true,
          url: "Decoration Modal Attributes",
          title: "Decoration Modal Attributes",
        },
      ];
      break;
    case "components-viewstate-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "How to use",
          title: "How to use",
        },
        {
          url: "References",
          title: "References",
        },
      ];
      break;
    case "components-radiobutton-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Themes",
          title: "Themes",
        },
        {
          url: "Base Components",
          title: "Base Components",
        },
        {
          url: "Radio Button Group",
          title: "Radio Button Group",
        },
        {
          url: "Radio Button Container",
          title: "Radio Button Container",
        },
      ];
      break;
    case "components-radiobutton-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Single",
          title: "Single",
        },
        {
          subMenu: true,
          url: "Group",
          title: "Group",
        },
      ];
      break;
    case "components-datatable-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-datepicker-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Size",
          title: "Size",
        },
        {
          url: "DateRange Picker",
          title: "DateRange Picker",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-megamenu-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-modal-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Basic Modal",
          title: "Basic",
        },
        {
          subMenu: true,
          url: "Modal with Content",
          title: "Modal with Content",
        },
        {
          subMenu: true,
          url: "Modal with Custom Height and Width",
          title: "Modal with Custom Height and Width",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-carousel-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic Carousel",
          title: "Basic Carousel",
        },
        {
          url: "Carousel with Indicator",
          title: "Carousel with Indicator",
        },
        {
          url: "Carousel with Subtitle",
          title: "Carousel with Subtitle",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-tabbar-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Horizontal",
          title: "Horizontal",
          subMenu: true,
        },
        {
          url: "Vertical",
          title: "Vertical",
          subMenu: true,
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-alert-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-chips-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-rating-android-compose":
      heading = [
        {
          url: "Variants",
          title: "Variants",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Rating",
          title: "Rating",
        },
        {
          subMenu: true,
          url: "State",
          title: "State",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-stepper-website":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Basic",
          title: "Basic",
        },
        {
          url: "Props",
          title: "Props",
        },
      ];
      break;
    case "components-progress-circle-android-compose":
      heading = [
        {
          url: "Variants",
          title: "Variants",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Progress",
          title: "Progress",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-android-bottomsheet":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Basic implement",
          title: "Basic implement",
        },
        {
          subMenu: true,
          url: "Title with handler implement",
          title: "Title with handler implement",
        },
        {
          subMenu: true,
          url: "Button with handler or without handler",
          title: "Button with handler or without handler",
        },
        {
          subMenu: true,
          url: "Icon left with handler or without handler",
          title: "Icon left with handler or without handler",
        },
        {
          subMenu: true,
          url: "Hide or show close button",
          title: "Hide or show close button",
        },
        {
          subMenu: true,
          url: "Hide header",
          title: "Hide header",
        },
        {
          url: "Themes",
          title: "Themes",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-android-compose-bottomsheet":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-alert-website-v2":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
        {
          subMenu: true,
          url: "Sub Menu One",
          title: "Sub Menu One",
        },
        {
          subMenu: true,
          url: "Sub Menu Two",
          title: "Sub Menu Two",
        },
        {
          url: "Example",
          title: "Example",
        },
      ];
      break;
    case "components-alert-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "Dismiss button without Action button",
          title: "Dismiss button without Action button",
        },
        {
          subMenu: true,
          url: "Without Action button and Dismiss button",
          title: "Without Action button and Dismiss button",
        },
        {
          subMenu: true,
          url: "Action button with Dismiss button",
          title: "Action button with Dismiss button",
        },
        {
          subMenu: true,
          url: "Action button without Dismiss button",
          title: "Action button without Dismiss button",
        },
        {
          url: "Themes",
          title: "Themes",
        },
        {
          subMenu: true,
          url: "Info",
          title: "Info",
        },
        {
          subMenu: true,
          url: "Success",
          title: "Success",
        },
        {
          subMenu: true,
          url: "Warning",
          title: "Warning",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-alert-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Themes",
          title: "Themes",
        },
        {
          subMenu: true,
          url: "Info",
          title: "Info",
        },
        {
          subMenu: true,
          url: "Success",
          title: "Success",
        },
        {
          subMenu: true,
          url: "Warning",
          title: "Warning",
        },
        {
          subMenu: true,
          url: "Error",
          title: "Error",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-badge-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          subMenu: true,
          url: "Appearance",
          title: "Appearance",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
        {
          url: "Example",
          title: "Example",
        },
      ];
      break;
    case "components-badge-android-compose":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variants",
          title: "Variants",
        },
        {
          subMenu: true,
          url: "Size",
          title: "Size",
        },
        {
          subMenu: true,
          url: "Appearance",
          title: "Appearance",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-badge-ios":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Attribute",
          title: "Attribute",
        },
      ];
      break;
    case "components-tooltip-android":
      heading = [
        {
          url: "Variant",
          title: "Variant",
        },
        {
          subMenu: true,
          url: "1. Tooltip Default/Base",
          title: "1. Tooltip Default/Base",
        },
        {
          subMenu: true,
          url: "2. Tooltip Arrow",
          title: "2. Tooltip Arrow",
        },
        {
          subMenu: true,
          url: "3. Tooltip Default/Base + Icon",
          title: "3. Tooltip Default/Base + Icon",
        },
        {
          subMenu: true,
          url: "4. Tooltip Arrow + Icon",
          title: "4. Tooltip Arrow + Icon",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-tooltip-android-compose":
      heading = [
        {
          url: "Orientations and Animations",
          title: "Orientations and Animations",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Orientation",
          title: "Orientation",
        },
        {
          subMenu: true,
          url: "Start",
          title: "Start",
        },
        {
          subMenu: true,
          url: "Top",
          title: "Top",
        },
        {
          subMenu: true,
          url: "Bottom",
          title: "Bottom",
        },
        {
          subMenu: true,
          url: "End",
          title: "End",
        },
        {
          url: "Animation",
          title: "Animation",
        },
        {
          subMenu: true,
          url: "Heartbeat",
          title: "Heartbeat",
        },
        {
          subMenu: true,
          url: "Rotate",
          title: "Rotate",
        },
        {
          subMenu: true,
          url: "Shake",
          title: "Shake",
        },
        {
          subMenu: true,
          url: "Breath",
          title: "Breath",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-tabbar-android":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "dashboard-pattern-guideline":
      heading = [
        {
          url: "information-design-and-user-experience",
          title: "Information design & user experience",
        },
        {
          url: "data-visualization",
          title: "Data Visualization",
        },
        {
          url: "hierarchy-layout",
          title: "Hierarchy Layout",
        },
        {
          url: "table-and-data-filter",
          title: "Table and Data Filter",
        },
        {
          url: "form-for-submit-data",
          title: "Form for Submit Data",
        },
      ];
      break;
    case "dashboard-pattern-use-case":
      heading = [
        {
          url: "case-:-sales-monitoring-dashboard",
          title: "Case : Sales Monitoring Dashboard",
        },
        {
          url: "case-:-customer-service-monitoring-dashboard",
          title: "Case : Customer Service Monitoring Dashboard",
        },
        {
          url: "case-:-web-analytic-dashboard",
          title: "Case : Web Analytic Dashboard",
        },
        {
          url: "case-:-product-performance-management-dashboard",
          title: "Case : Product Performance Management Dashboard",
        },
      ];
      break;
    case "foundation-typography-ios":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Usage Default LegionUI",
          title: "Usage Default LegionUI",
        },
        {
          subMenu: true,
          url: "Usage Theme",
          title: "Usage Theme",
        },
        {
          url: "Heading",
          title: "Heading",
        },
        {
          subMenu: true,
          url: "Heading Default LegionUI",
          title: "Heading Default LegionUI",
        },
        {
          subMenu: true,
          url: "Heading Theme",
          title: "Heading Theme",
        },
        {
          url: "Body",
          title: "Body",
        },
        {
          subMenu: true,
          url: "Body Default LegionUI",
          title: "Body Default LegionUI",
        },
        {
          subMenu: true,
          url: "Body Theme",
          title: "Body Theme",
        },
        {
          url: "Caption",
          title: "Caption",
        },
        {
          subMenu: true,
          url: "Caption Default LegionUI",
          title: "Caption Default LegionUI",
        },
        {
          subMenu: true,
          url: "Caption Theme",
          title: "Caption Theme",
        },
      ];
      break;
    case "illustrations-overview":
      heading = [
        {
          url: "illustration-for-designers",
          title: "Illustration For Designers",
        },
        {
          url: "various-illustrations-type",
          title: "Various Illustrations Type",
        },
        {
          url: "where-illustrations-are-needed",
          title: "Where Illustrations are Needed",
        },
      ];
      break;
    case "illustrations-character-guidance":
      heading = [
        {
          url: "overview",
          title: "Overview",
        },
        {
          url: "body-type",
          title: "Body Type",
        },
        {
          url: "body-ratio",
          title: "Body Ratio",
        },
        {
          url: "female-vs-male-faces",
          title: "Female vs Male Ratio",
        },
        {
          url: "expression",
          title: "Expression",
        },
      ];
      break;
    case "components-alert-ios":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          url: "Variant",
          title: "Variant",
        },
        {
          url: "Properties",
          title: "Properties",
        },
        {
          url: "Example Project",
          title: "Example Project",
        },
      ];
      break;
    case "components-android-bottombar":
      heading = [
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Basic Bottom Bar",
          title: "Basic Bottom Bar",
        },
        {
          subMenu: true,
          url: "Bottom bar with Middle button",
          title: "Bottom bar with Middle button",
        },
        {
          subMenu: true,
          url: "Kotlin Code",
          title: "Kotlin Code",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "components-pin-input-android-compose":
      heading = [
        {
          url: "Variants",
          title: "Variants",
        },
        {
          url: "Usage",
          title: "Usage",
        },
        {
          subMenu: true,
          url: "Pin Input Reguler",
          title: "Pin Input Reguler",
        },
        {
          subMenu: true,
          url: "Pin Input Error",
          title: "Pin Input Error",
        },
        {
          subMenu: true,
          url: "Pin Input Success",
          title: "Pin Input Success",
        },
        {
          url: "Attributes",
          title: "Attributes",
        },
      ];
      break;
    case "pattern-loginandregister-guideline":
      heading = [
        {
          url: "Introduction",
          title: "Introduction",
        },
        {
          url: "Forms",
          title: "Forms",
        },
        {
          url: "Dialog",
          title: "Dialog",
        },
        {
          url: "Error State",
          title: "Error State",
        },
      ];
      break;
    case "graphicon-guideline":
      heading = [
        {
          url: "introduction",
          title: "Introduction",
        },
        {
          url: "grid-and-keyline",
          title: "Grid & Keyline",
        },
        {
          url: "sizing",
          title: "Sizing",
        },
        {
          url: "shape",
          title: "Shape",
        },
        {
          url: "object",
          title: "Object",
        },
        {
          url: "alignment",
          title: "Alignment",
        },
      ];
      break;
    case "graphicon-usage":
      heading = [
        {
          url: "overview",
          title: "Overview",
        },
        {
          url: "study-case:-business-to-business-(b2b)",
          title: "Study Case: Business-to-Business (B2B)",
        },
        {
          url: "study-case:-business-to-consumer-(b2c)",
          title: "Study Case: Business-to-Consumer (B2C)",
        },
        {
          url: "study-case:-government-to-citizen-(g2c)",
          title: "Study Case: Government-to-Citizen (G2C)",
        },
        {
          url: "unit-and-consistency",
          title: "Unit & Consistency",
        },
      ];
      break;
    default:
      heading = [];
      break;
  }
  return heading;
};

export default HeadingTableOfContents;
